export default (function (vertical) {
    switch (vertical) {
        case "a":
            return "/accommodation";
        case "o":
            return "/health-cover";
        case "b":
            return "/banking";
        case "m":
            return "/mobile-and-data";
        default:
            throw new Error("Unknown vertical ".concat(vertical));
    }
});
