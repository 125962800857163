var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import getUrlByVertical from "../../../router/getUrlByVertical";
import gql from "graphql-tag";
export default Vue.extend({
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        post: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment BlogPostTags_post on BlogPost {\n        tags {\n          key\n          name\n        }\n        tagCities {\n          slug\n          name\n        }\n      }\n    "], ["\n      fragment BlogPostTags_post on BlogPost {\n        tags {\n          key\n          name\n        }\n        tagCities {\n          slug\n          name\n        }\n      }\n    "]))),
    },
    computed: {
        tagsWithUrls: function () {
            var urls = this.post.tags.map(function (_a) {
                var key = _a.key, name = _a.name;
                return ({
                    name: name,
                    url: getUrlByVertical(key),
                });
            });
            return __spreadArray(__spreadArray([], urls, true), this.post.tagCities.map(function (_a) {
                var name = _a.name, slug = _a.slug;
                return ({
                    name: name,
                    url: "/accommodation/".concat(slug),
                });
            }), true);
        },
    },
});
var templateObject_1;
