var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { createRelayConnectionFetchMoreQueryUpdater } from "../../../utils/apollo";
import BlogPostTags from "./BlogPostTags.vue";
import BlogPostContentLoading from "./BlogPostContentLoading.vue";
var pageSize = 8;
export default Vue.extend({
    components: {
        BlogPostTags: BlogPostTags,
        BlogPostContentLoading: BlogPostContentLoading,
    },
    computed: {
        posts: function () {
            if (!this.liveBlogPosts) {
                return [];
            }
            return this.liveBlogPosts.edges.map(function (_a) {
                var node = _a.node;
                return node;
            });
        },
        hasMorePosts: function () {
            return !!this.liveBlogPosts && this.liveBlogPosts.pageInfo.hasNextPage;
        },
    },
    methods: {
        onLoadMore: function () {
            if (this.liveBlogPosts) {
                this.$apollo.queries.liveBlogPosts.fetchMore({
                    variables: {
                        after: this.liveBlogPosts.pageInfo.endCursor,
                    },
                    updateQuery: createRelayConnectionFetchMoreQueryUpdater("liveBlogPosts"),
                });
            }
        },
    },
    apollo: {
        liveBlogPosts: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query BlogQuery($first: Int!, $after: String) {\n          liveBlogPosts(first: $first, after: $after) {\n            edges {\n              node {\n                id\n                title\n                slug\n                excerpt\n                fullSizeImage\n                ...BlogPostTags_post\n              }\n            }\n            pageInfo {\n              hasNextPage\n              endCursor\n            }\n          }\n        }\n        ", "\n      "], ["\n        query BlogQuery($first: Int!, $after: String) {\n          liveBlogPosts(first: $first, after: $after) {\n            edges {\n              node {\n                id\n                title\n                slug\n                excerpt\n                fullSizeImage\n                ...BlogPostTags_post\n              }\n            }\n            pageInfo {\n              hasNextPage\n              endCursor\n            }\n          }\n        }\n        ", "\n      "])), BlogPostTags.options.fragments.post),
            variables: function () {
                return {
                    first: pageSize,
                };
            },
        },
    },
});
var templateObject_1;
